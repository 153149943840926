<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="9" class="d-flex justify-center align-center ">
          <v-img
            max-width="40%"
            src="../../assets/sobre_territorio_camargos_07.png"
          ></v-img>
          <div class="d-flex flex-column justify-space-between poem">
            <p>
              <span>
                "CAMARGOS
              </span>
              <span>
                ACONCHEGANTE
              </span>
              <span>
                PEQUENINO!
              </span>
              <span>
                PARECE UM PRESEPINHO, CHEIO DE AMOR E CARINHO."
              </span>
            </p>

            <p style="font-size: 0.6em; margin-bottom: 0;">
              Maria de Lurdes André Pereira, Dona Cota.
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card
            title="Características do Núcleo urbano"
            titleColor="#000"
            borderColor="#e06919"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                O núcleo urbano de Camargos foi se conformando no início do
                século XVIII, a partir da construção de casas em estilo
                colonial, assim como da Igreja matriz de Nossa Senhora da
                Conceição no alto da colina, com influência portuguesa. O
                casario está assentado sobre uma encosta, e as edificações foram
                implantadas ao longo de duas ruas que acompanham as curvas de
                nível do terreno.
              </p>
              <p>
                Na base da serra, no vale conformado pelo conjunto de montanhas
                do local, encontra-se o Córrego Tesoureiro, de extrema
                importância para a população e para as atividades produtivas
                desenvolvidas na comunidade.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_camargos_08.png"
          />
          <p style="font-size: 0.6em; text-align: center;">
            Vista a partir da Rua do Cruzeiro da Igreja Matriz de Nossa Senhora
            da Conceição, situada no alto do morro, e do Cruzeiro de pedras em
            cota inferior. Foto: Flora Passos, 2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_camargos_09.png"
          />
          <p style="font-size: 0.6em; text-align: center;">
            Vista de parte do entorno montanhoso da região de Camargos, coberto
            por áreas de mata e extensões de pastagem. Foto: Flora Passos, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="5" class="d-flex justify-center align-center">
          <img
            width="100%"
            src="../../assets/sobre_territorio_camargos_cruzeiro.png"
          />
        </v-col>

        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center"
        >
          <div class="d-flex flex-column justify-space-between poem">
            <p>
              <span>
                "CAMARGOS TEM SUA HISTÓRIA
              </span>
              <span>
                AS RUÍNAS DO TESOUREIRO, A ESTRADA REAL
              </span>
              <span>
                QUE LHE DEU REALEZA E GLÓRIA."
              </span>
            </p>

            <p style="font-size: 0.6em; margin-bottom: 0;">
              Maria de Lurdes André Pereira, Dona Cota.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                De grande relevância e representação entre as edificações
                religiosas do período colonial, a Igreja Matriz foi tombada pelo
                IPHAN em 1949. Entre outras construções e localidades que se
                encontravam em Camargos desde o século XVIII, com grande
                importância sócio-econômica e cultural da região, voltadas para
                a produção agrícola estão a Fazenda do Gualaxo, da qual restaram
                ruínas, bem como a sede e as construções de uso rural da Fazenda
                da Palha. Sobressaem-se ainda as ruínas da Fazenda do
                Tesoureiro, relacionada à fase de exploração do ouro e que
                pertenceu ao senador do Império, o primeiro Barão de Camargos,
                destacando-se no século XIX pela produção de chá e café.
              </p>
              <p>
                O distrito possui em torno de 30 imóveis urbanos, em sua maioria
                de uso residencial, parte deles utilizados para descanso e lazer
                nos finais de semana. Ressalta-se que, conforme relato dos
                moradores, vários desses imóveis e até mesmo edificações que se
                encontram em ruínas, abrigaram vendas e mercearias que ainda
                continuaram funcionando depois do período colonial, permanecendo
                esse uso nos séculos seguintes. O conjunto urbano foi abrangido
                no Inventário de Proteção do Patrimônio Cultural do Município de
                Mariana nos anos de 2004 e 2006 e tombado em âmbitomunicipal no
                ano de 2012.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_10.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Vistas de uma edificação habitacional situada à frente do cruzeiro
            de pedras, construída com técnicas tradicionais, sendo o telhado com
            engradamento de madeira e a cobertura de telhas de barro do tipo
            capa e bica. Vãos de portas e janelas com vergas ogivais,
            enquadramento de madeira e fechamento com folhas de madeira. Foto:
            Flora Passos, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_11.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Vista de um conjunto de casas situadas à Rua do Cruzeiro,
            construídas no alinhamento da via, apresentando um pavimento,
            telhado cerâmico sobre estrutura de madeira, Foto: Flora Passos,
            2018.
          </p>
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_12.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Vistas de um conjunto de casas localizadas à Rua do Cruzeiro,
            dispostas em um pavimento, sobre o alinhamento da rua. Fotos: Flora
            Passos, 2018.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A organização segue o modelo dos núcleos urbanos mineiros do
                período colonial, onde as edificações eram construídas no
                alinhamento da via. O conjunto possui duas ruas principais que
                acompanham as curvas de nível (pontos de mesma altitude), a Rua
                do Cruzeiro em cotas de menor altitude e a Rua Barão de Camargos
                em plano mais elevado, além de outros caminhos perpendiculares a
                elas, que então vencem a declividade da encosta. O piso dos
                logradouros era em parte de terra compactada pelo uso e em
                certos trechos, com pavimentação do tipo pé de moleque. Depois
                do rompimento da barragem, a Rua do Cruzeiro vem recebendo obras
                de pavimentação com calçamento poliédrico e teve o greide
                elevado, de modo que as edificações passaram a ter o nível do
                piso interno abaixo da altura da rua, trazendo umidade
                ascendente às alvenarias e demais elementos construtivos, em boa
                parte com fechamento de pau a pique, esteios e pisos de madeira.
              </p>
              <p>
                As edificações apresentam gabarito de um pavimento e
                características tipológicas das construções da época colonial,
                com predominância de cheios (paredes) sobre vazios (vãos de
                portas e janelas) nas fachadas, esquadrias de madeira, telhados
                prismáticos ou feitos em duas águas, com telhas cerâmicas
                curvas.
              </p>
              <p>
                O rejeito proveniente do rompimento da Barragem de Fundão não
                alcançou as edificações consolidadas no núcleo urbano do
                distrito de Camargos, porém atingiu os terrenos ao longo dos
                cursos d’água, estradas, pontes, trilhas e cachoeiras próximas à
                comunidade.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="9" class="d-flex justify-center align-center ">
          <v-img
            max-width="40%"
            src="../../assets/sobre_territorio_camargos_13.png"
          ></v-img>
          <div class="d-flex flex-column justify-space-between poem">
            <p>
              <span>
                "A ESTRADA É DE CHÃO
              </span>
              <span>
                NÃO TEM ASFALTO, NÃO
              </span>
              <span>
                MAS, TEM FLORES, CACHOEIRA
              </span>
              <span>
                E CICLISTAS CURTINDO ESTE CHÃO."
              </span>
            </p>

            <p style="font-size: 0.6em; margin-bottom: 0;">
              Maria de Lurdes André Pereira, Dona Cota.
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O distrito vinha nos últimos anos atraindo turistas devido a
                suas paisagens naturais, trilhas e cachoeiras, sendo que a
                citada Fazenda da Palha foi vinculada ao circuito turístico da
                região com seu restaurante. Em consequência da ação da lama nas
                trilhas, cursos d’água e cachoeiras próximas à comunidade, o
                circuito turístico da região foi consideravelmente afetado.
                Parte importante dos turistas que frequentava a região passava
                anteriormente pelo subdistrito de Bento Rodrigues, e, com a
                devastação daquela comunidade, o turismo decaiu
                consideravelmente. A ponte centenária que ligava Camargos a
                Bento Rodrigues também foi destruída, contribuindo para a
                decrescente procura por esse território.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="9" class="d-flex justify-center align-center ">
          <v-img
            max-width="40%"
            src="../../assets/sobre_territorio_camargos_07.png"
          ></v-img>
          <div class="d-flex flex-column justify-space-between poem">
            <p>
              <span>"CAMARGOS É TERRA DE AMORES</span>
              <span>CHEIA DE LINDAS FLORES,</span>
              <span>DE UM CÉU AZUL COR DE ANIL</span>
              <span>DE BRISA LEVE E SUAVE</span>
              <span>DE NOITES ENLUARADAS</span>
              <span>CHEIAS DE ENCANTOS MIL."</span>
            </p>

            <p style="font-size: 0.6em; margin-bottom: 0;">
              Maria de Lurdes André Pereira, Dona Cota.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Além do potencial turístico que Camargos possuía, os terrenos,
                em sua maioria de grandes extensões, apresentam quintais
                produtivos, onde são cultivadas hortas, árvores frutíferas,
                criados animais e plantados jardins caprichados compondo com as
                casas e edificações acessórias de uso rural a ocupação do solo.
                Dos quintais vinha a maior parte dos produtos consumidos pelas
                famílias, que, além de gerar economia por dispensar a compra no
                mercado, garantia a
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_cultura_alimentar' }"
                  >alimentação saudável</router-link
                >, livre de agrotóxicos e fertilizantes químicos.
              </p>
              <p>
                Nos sítios e fazendas que fazem parte do distrito, além da
                produção de hortaliças, roçados, frutas, criação de animais e
                fabricação artesanal de derivados de leite, por exemplo, era
                comum a realização de atividades de plantio de eucalipto e
                produção de carvão vegetal.
              </p>
              <p>
                Nos cursos d’água, os moradores e visitantes pescavam para lazer
                e consumo próprio, além de se banharem. Os rios e cachoeiras
                eram assim de grande importância para o convívio social, que
                ocorria também nas festividades tradicionais, em sua maioria
                relacionadas à religiosidade católica. Além das riquezas
                naturais relativas aos corpos hídricos, vegetação de mata
                nativa, bem como do ouro encontrado desde o início século XVIII,
                a região apresenta grande potencial de extração de minério de
                ferro, estando Camargos em zona de interesse de atuação das
                empresas mineradoras instaladas nos municípios de Mariana e Ouro
                Preto. Observa-se na região grandes extensões de propriedades da
                mineradora Vale S. A., com suas placas de identificação que vão
                ocupando o território.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_14.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Vista da Cachoeira de Camargos. Foto: Flora Passos, 2016.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_15.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Vista de um fogão a lenha, equipamento presente nas cozinhas das
            casas de Camargos, onde o fogo permanece aceso para o preparo das
            comidas típicas do lugar. Foto: Flora Passos, 2018.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_16.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Imagem de uma churrasqueira construída com tijolos cerâmicos,
            bastante presente nas áreas de lazer e convívio das casas de
            Camargos. Foto: Flora Passos, 2016.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_17.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Placa de sinalização indicando propriedade da mineradora Samarco
            S.A. em terreno da região de Camargos. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}
.poem p span {
  display: block;
  font-size: 0.8em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  margin-top: 2em;
}

.inside__card p {
  font-size: 0.6em;
}
</style>
